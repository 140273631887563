import React, { useEffect, useState } from "react";
import HeaderImg from "../components/HeaderImg";
import ActionBox from "../components/ActionBox";
import Leaders from "../components/Leaders";
import Awards from "../components/Awards";
import Numbers from "../components/Numbers";
import { toast } from "react-toastify";
import { fetchImagesByPageName } from "../api/main";

const About = () => {
  const [images, setImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    const handleFetchPageImage = async () => {
      setLoadingImage(true);
      try {
        const response = await fetchImagesByPageName("About");

        setImages(response.data);

        setLoadingImage(false);
      } catch (err) {
        setLoadingImage(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchPageImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderImg
        title="About Lilysolutions"
        disc="Our Expertise. Know more about what we do"
      />
      {/*=================================
    History */}
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="section-title text-center">
                <h2>
                  Founded in 2018, Lilysolutions Limited is a dynamic business
                  support firm dedicated to helping businesses thrive in the
                  digital age.
                </h2>
                <p className="px-xl-5">
                  Our comprehensive services range from digital marketing and
                  advertising to web development, branding, business support,
                  and public and media relations. We pride ourselves on
                  delivering tailored solutions that elevate brands and
                  accelerate growth, ensuring our clients achieve their business
                  goals.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="cd-horizontal-timeline">
                <div className="timeline">
                  <div className="events-wrapper">
                    <div className="events">
                      <ul>
                        <li>
                          <a
                            href="#0"
                            data-date="01/01/2010"
                            className="selected"
                          >
                            2018
                          </a>
                        </li>
                        <li>
                          <a href="#0" data-date="01/01/2012">
                            2019
                          </a>
                        </li>
                        <li>
                          <a href="#0" data-date="01/01/2014">
                            2020
                          </a>
                        </li>
                        <li>
                          <a href="#0" data-date="01/01/2016">
                            2021
                          </a>
                        </li>
                        <li>
                          <a href="#0" data-date="01/01/2018">
                            2022
                          </a>
                        </li>
                        <li>
                          <a href="#0" data-date="01/01/2020">
                            2023
                          </a>
                        </li>
                        <li>
                          <a href="#0" data-date="01/01/2022">
                            2024
                          </a>
                        </li>
                      </ul>
                      <span className="filling-line" aria-hidden="true" />
                    </div>
                    {/* .events */}
                  </div>
                  {/* .events-wrapper */}
                  <ul className="cd-timeline-navigation">
                    <li>
                      <a href="#0" className="prev inactive" />
                    </li>
                    <li>
                      <a href="#0" className="next" />
                    </li>
                  </ul>
                  {/* .cd-timeline-navigation */}
                </div>
                {/* .timeline */}
                <div className="events-content">
                  <ul>
                    <li className="selected" data-date="01/01/2010">
                      <div className="row">
                        <div className="col-md-4 position-relative">
                          <h1 className="year">2018</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="timeline-text">
                            <h6 className="text-dark"> The Inception</h6>
                            <p className="border-start ps-3 font-italic">
                              Lilysolutions Limited was established with a clear
                              vision: to provide unparalleled business support
                              services.
                            </p>
                            <p className="mb-0">
                              Our journey began with a small team of passionate
                              professionals committed to making a difference in
                              the industry. In our first year, we focused on
                              building a strong foundation, establishing our
                              core services, and forging relationships with our
                              initial clients.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li data-date="01/01/2012">
                      <div className="row">
                        <div className="col-md-4 position-relative">
                          <h1 className="year">2019</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="timeline-text">
                            <h6 className="text-dark"> Building Momentum</h6>
                            <p className="border-start ps-3 font-italic">
                              In 2019, we expanded our service offerings and
                              grew our team to include experts in various
                              fields.
                            </p>
                            <p className="mb-0">
                              {" "}
                              This year marked our entry into the digital
                              marketing and web development sectors, where we
                              successfully launched several high-impact
                              campaigns and bespoke websites for our clients.
                              Our commitment to excellence began to set us apart
                              in the competitive market.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li data-date="01/01/2014">
                      <div className="row">
                        <div className="col-md-4 position-relative">
                          <h1 className="year">2020</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="timeline-text">
                            <h6 className="text-dark">Embracing Challenges</h6>
                            <p className="border-start ps-3 font-italic">
                              The global pandemic brought unprecedented
                              challenges, but it also highlighted the importance
                              of digital transformation.
                            </p>
                            <p className="mb-0">
                              We adapted quickly, helping businesses pivot their
                              strategies to navigate the new landscape. Our
                              innovative solutions in digital marketing,
                              branding, and web development enabled our clients
                              to maintain their competitive edge despite the
                              uncertainties.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li data-date="01/01/2016">
                      <div className="row">
                        <div className="col-md-4 position-relative">
                          <h1 className="year">2021</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="timeline-text">
                            <h6 className="text-dark">Expanding Horizons</h6>
                            <p className="border-start ps-3 font-italic">
                              2021 was a year of significant growth and
                              diversification.
                            </p>
                            <p className="mb-0">
                              We introduced new services such as influencer
                              marketing, advanced SEO strategies, and mobile app
                              development. Our team continued to grow, bringing
                              in fresh talent and expanding our capabilities.
                              This year also saw the successful launch of our
                              podcast series, providing valuable insights and
                              fostering community engagement.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li data-date="01/01/2018">
                      <div className="row">
                        <div className="col-md-4 position-relative">
                          <h1 className="year">2022</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="timeline-text">
                            <h6 className="text-dark">
                              Strengthening Partnerships
                            </h6>
                            <p className="border-start ps-3 font-italic">
                              We focused on building strong partnerships with
                              industry leaders and innovators.{" "}
                            </p>
                            <p className="mb-0">
                              Collaborations with companies like SherryBlinks
                              Studio, Order Food, Travel Gold, and SBTV enhanced
                              our service delivery and broadened our impact. Our
                              commitment to client success remained unwavering,
                              as we continued to deliver tailored solutions that
                              drove measurable results.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li data-date="01/01/2020">
                      <div className="row">
                        <div className="col-md-4 position-relative">
                          <h1 className="year">2023</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="timeline-text">
                            <h6 className="text-dark">
                              Innovating for the Future
                            </h6>
                            <p className="border-start ps-3 font-italic">
                              Innovation was at the forefront in 2023.
                            </p>
                            <p className="mb-0">
                              We invested in cutting-edge technologies and
                              advanced our expertise in AI-driven marketing
                              strategies, responsive web design, and
                              user-centric UI/UX design. Our efforts paid off as
                              we received several industry awards and
                              recognition for our contributions to digital
                              marketing and web development.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li data-date="01/01/2022">
                      <div className="row">
                        <div className="col-md-4 position-relative">
                          <h1 className="year">2024</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="timeline-text">
                            <h6 className="text-dark">Leading the Way</h6>
                            <p className="border-start ps-3 font-italic">
                              Today, in 2024, Lilysolutions Limited stands as a
                              trusted leader in business support services.
                            </p>
                            <p className="mb-0">
                              Our journey from a small startup to an industry
                              leader is a testament to our dedication,
                              innovation, and client-centric approach. We
                              continue to push the boundaries, offering
                              solutions that not only meet but exceed our
                              clients' expectations. As we look to the future,
                              we remain committed to helping businesses navigate
                              the ever-evolving digital landscape.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* .events-content */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    History */}
      {/*=================================
    portfolio */}
      <section className="space-pb popup-gallery overflow-hidden">
        <div className="container-fluid">
          <div className="row d-flex align-items-end">
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href={images?.[1]?.url}>
                <img className="img-fluid" src={images?.[1]?.url} alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href={images?.[2]?.url}>
                <img
                  className="img-fluid w-100"
                  src={images?.[2]?.url}
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href={images?.[3]?.url}>
                <img className="img-fluid" src={images?.[3]?.url} alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href={images?.[4]?.url}>
                <img className="img-fluid" src={images?.[4]?.url} alt="" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 mt-0 mt-lg-3">
              <a className="portfolio-img" href={images?.[5]?.url}>
                <img className="img-fluid" src={images?.[5]?.url} alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-3">
              <a className="portfolio-img" href={images?.[6]?.url}>
                <img
                  className="img-fluid w-100"
                  src={images?.[6]?.url}
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-3">
              <a className="portfolio-img" href={images?.[7]?.url}>
                <img className="img-fluid" src={images?.[7]?.url} alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-3">
              <a className="portfolio-img" href={images?.[8]?.url}>
                <img className="img-fluid" src={images?.[8]?.url} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    portfolio */}

      <Numbers />

      <Leaders />

      <Awards />

      <ActionBox />
    </>
  );
};

export default About;
