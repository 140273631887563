import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL + "/api";

export const fetchSiteInfo = () => {
  return axios.get(`${apiURL}/site/all`);
};

export const fetchServices = () => {
  return axios.get(`${apiURL}/service/all`);
};

export const fetchBlogs = (page, limit) => {
  return axios.get(`${apiURL}/blog/all/${page}/${limit}`);
};

export const fetchBlog = (id) => {
  return axios.get(`${apiURL}/blog/${id}`);
};

export const getAllPrices = () => {
  return axios.get(`${apiURL}/price/all`);
};

export const fetchTestimonials = () => {
  return axios.get(`${apiURL}/testimonial/all`);
};

export const fetchPortfolios = (token) => {
  return axios.get(`${apiURL}/portfolio/all`);
};

export const fetchTeams = () => {
  return axios.get(`${apiURL}/team/all/1/100`);
};

export const fetchImagesByPageName = (page) => {
  return axios.get(`${apiURL}/image/${page}`);
};
