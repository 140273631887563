import React, { useEffect, useState } from "react";
import yt3 from "../assets/images/yt3.png";
import client from "../assets/images/client.jpeg";
import { toast } from "react-toastify";
import { fetchTestimonials } from "../api/main";
import Loading from "../components/Loading";

const Testemonial = () => {
  const [testimoials, setTestimoials] = useState([]);
  const [loading, setLoading] = useState(false);

  const logos = [
    "images/Downloads/LOGOS/logo1.png",
    "images/Downloads/LOGOS/logo2.png",
    "images/Downloads/LOGOS/logo3.png",
    "images/Downloads/LOGOS/logo4.png",
    "images/Downloads/LOGOS/logo5.png",
    "images/Downloads/LOGOS/logo6.png",
    "images/Downloads/LOGOS/logo7.png",
    "images/Downloads/LOGOS/logo8.png",
    "images/Downloads/LOGOS/logo9.png",
  ];

  useEffect(() => {
    const handleFetchBlogs = async () => {
      setLoading(true);
      try {
        const res = await fetchTestimonials();

        setTestimoials(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("testimoials", testimoials);
  return (
    <div>
      <section className="py-6 bg-overlay-black-90 bg-holder headerImg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="section-title position-relative">
                <h2 className="text-white">Our Testimonials</h2>
                <p className="text-white">
                  We are happy to find that our service is such a success and
                  can be so profitable to many people and enterprise clients.
                </p>
              </div>
            </div>
          </div>
          <div className="row  mt-5">
            <div className="col-lg-12">
              <div className="brand-logo">
                <div
                  className="owl-carousel testimonial-center owl-nav-bottom-center"
                  data-nav-arrow="false"
                  data-items={5}
                  data-md-items={4}
                  data-sm-items={4}
                  data-xs-items={3}
                  data-xx-items={2}
                  data-space={40}
                  data-autoheight="true"
                >
                  {logos.map((logo) => {
                    return (
                      <div
                        style={{ background: "#fff", borderRadius: 10 }}
                        key={logo}
                        className="item"
                      >
                        <img
                          className="img-fluid center-block mx-auto"
                          src={logo}
                          alt=""
                        />
                      </div>
                    );
                  })}
                  {/* <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/02.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/03.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/04.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/05.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/06.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/07.svg"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pt">
        {loading ? (
          <Loading />
        ) : (
          <div className="container">
            <div className="row mb-0 mb-lg-4">
              <div className="col-lg-6 text-center">
                <div className="testimonial-style-02 h-100 shadow-none border">
                  <div className="item">
                    <div className="video-image">
                      <img
                        className="img-fluid w-100"
                        src={yt3}
                        style={{ height: 400, objectFit: "cover" }}
                        alt=""
                      />
                      {/* <div
                      style={{
                        height: 400,
                        background: "#bababa",
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                      }}
                    ></div> */}

                      <a
                        className="popup-icon popup-youtube"
                        href="https://www.youtube.com/watch?v=cWJGIFFulyw"
                      >
                        <i className="fa fa-play" />
                        <div className="svg-item">
                          <svg
                            version="1.1"
                            id="Layer_2"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            height="48px"
                            viewBox="0 0 1920 48"
                            style={{ enableBackground: "new 0 0 1920 48" }}
                            xmlSpace="preserve"
                          >
                            <polygon
                              id="XMLID_1_"
                              className="st0"
                              fill="#ffffff"
                              points="1920,48 0,48 0,48 1920,0 "
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                    <div className="testimonial-item-sm pb-4">
                      <div className="testimonial-avatar-sm">
                        <img
                          className="img-fluid rounded-circle"
                          src={client}
                          style={{ height: 90 }}
                          alt=""
                        />
                      </div>
                      <div className="testimonial-content text-white">
                        <p>{testimoials?.[5]?.text}</p>
                      </div>
                      <div className="testimonial-author">
                        <div className="testimonial-name">
                          <h6 className="mb-1">- {testimoials?.[5]?.name}</h6>
                          <span>{testimoials?.[5]?.title}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <div className="testimonial-style-03 border-radius bg-light p-4 mb-4">
                  <div className="testimonial-item p-2">
                    <div className="testimonial-content">
                      <i className="fas fa-quote-left quotes text-dark" />
                      <p className="testimonial-des">
                        {testimoials?.[3]?.text}
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">- {testimoials?.[3]?.name}</h6>
                        <span>{testimoials?.[3]?.title}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-style-03 border-radius bg-light p-4">
                  <div className="testimonial-item p-2">
                    <div className="testimonial-content">
                      <i className="fas fa-quote-left quotes text-dark" />
                      <p className="testimonial-des">
                        {testimoials?.[2]?.text}
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">- {testimoials?.[2]?.name}</h6>
                        <span>{testimoials?.[2]?.title}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <section className="space-pb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="testimonial-style-03 border-radius bg-light p-4 mt-4 mt-lg-0">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes text-dark" />
                    <p className="testimonial-des">{testimoials?.[6]?.text}</p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">- {testimoials?.[6]?.name}d</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-4">
              <div
                className="testimonial-style-03 border-radius p-4 h-100 bg-overlay-black-70 bg-holder"
                style={{ background: "#022D62" }}
              >
                <div className="testimonial-item p-2 position-relative z-index-1">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes" />
                    <p className="text-white testimonial-des">
                      {testimoials?.[7]?.text}
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1 text-white">
                        - {testimoials?.[7]?.name}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="testimonial-style-03 border-radius p-4 bg-primary">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes" />
                    <p className="text-white testimonial-des">
                      {testimoials?.[1]?.text}
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1 text-white">
                        - {testimoials?.[1]?.name}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-style-03 border-radius bg-light p-4 mt-4">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes text-dark" />
                    <p className="testimonial-des">{testimoials?.[0]?.text}</p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">- {testimoials?.[0]?.name}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-4">
              <div className="testimonial-style-03 border-radius bg-dark p-4 h-100">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes" />
                    <p className="text-white testimonial-des">
                      {testimoials?.[4]?.text}
                    </p>
                    <p className="text-white testimonial-des">
                      The best platform ever, keep up the good work 💯👌✅
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1 text-white">
                        - {testimoials?.[4]?.name}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testemonial;
